import { inject } from '@angular/core';
import { type ActivatedRouteSnapshot, type CanActivateFn, Params, Router } from '@angular/router';
import { hasServiceSync } from '@auth/repository/auth.repository';
import { UME_SERVICE } from '@core/service-subscriptions/ume-services';
import { HOME_PAGE, PROVIDER } from '@shared/constants/ume-constants';
import { Observable } from 'rxjs';

const SAML = 'saml';

export const dashboardGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): boolean | Observable<boolean> => {
  const router = inject(Router);
  const isProvider: boolean = hasServiceSync(UME_SERVICE.PROVIDER);
  let params: Params = route.queryParams;
  const samlParam = params.hasOwnProperty(SAML);

  if (samlParam) {
    const copyParams = { ...params };
    delete copyParams[SAML];
    params = copyParams;

    isProvider
      ? router.navigate(['/', PROVIDER, HOME_PAGE], { queryParams: params })
      : router.navigate(['/', HOME_PAGE], { queryParams: params });

    return false;
  }

  return true;
};
